import React, { useContext } from 'react';
import { withCookies } from 'react-cookie';
import { frontloadConnect } from 'react-frontload';
import { connect, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { PortalConfigContext } from '../../config/portal';
import ErrorMessage from '../../components/ErrorMessage';
import Meta from '../../components/Meta';
import DefaultLayout from '../../layouts/Default';
import * as utils from '../../store/utils';
import * as actions from '../../store/actions';

import NotFoundPage from '../NotFoundPage';
import MainEditorialContent from './MainEditorialContent';
import { Redirect } from 'react-router-dom';

import {getSubdomain, removeEnvironmentSubdomain} from '../../tppServices/translations/helpers';
import {getDefaultI18Service} from '../../tppServices/tppDi';

const StaticContent = (props) => {
  const { location: { pathname } } = props;

  const context = useContext(PortalConfigContext);
  const { errors, success, statusCode, data } = useSelector(state => state.app);
  const redirectTo = data?.redirectTo;

  const renderStaticLayout = () => {
    if (errors) {
      return <NotFoundPage {...props} statusCode={ statusCode } />;
    }

    const isDefaultRouting = useSelector( state => state?.app?.defaultStaticRouting?.match || false );
    if (isDefaultRouting) {
      const metadata = data?.meta;
      const metaProps = metadata?.reduce((acc, item) => {
        acc[item.name] = item.content;
        return acc;
      }, {});

      return (
        <DefaultLayout {...props} pageType="DefaultStaticPage">
          <Meta {...metaProps} />
          <div className="static-content">
            {/* static content here */}
          </div>
        </DefaultLayout>
      );
    }

    return (
      <DefaultLayout {...props} pageType="EditorialPage">
        <MainEditorialContent />
      </DefaultLayout>
    );
  };

  const renderRedirect = () => {
    return (
      <Redirect to={{ pathname: redirectTo }} />
    );
  };

  const excludedStaticPages = context?.pages?.excludeStaticPaths;

  // If path exists in list of excluded static pages, render NotFoundPage.
  if (excludedStaticPages && excludedStaticPages.includes(pathname)) {
    return <ErrorMessage {...props} debug={'from static content'} statusCode={ statusCode } />;
  }

  // Render editorial layout on data acquisition
  if ((errors && statusCode < 500) || success) {
    if (redirectTo) {
      return renderRedirect();
    }
    return renderStaticLayout();
  }
  return <ErrorMessage {...props} debug={'from static 2'} statusCode={ statusCode } />;
};

StaticContent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  redirectTo: PropTypes.string,
};

const loadStaticResultsData = async ({ dispatch, location }) => {
  const defaultI18Service = getDefaultI18Service();
  const getCurrentHost = defaultI18Service.getCurrentHost;
  const currentHost = removeEnvironmentSubdomain(getCurrentHost());
  const currentSubdomain = getSubdomain(currentHost);
  if (utils.isServer() || actions.shouldGetData(location)) {
    return dispatch(actions.getStaticContent(location, currentSubdomain));
  }
};

const StaticContentWithDataLoaded = frontloadConnect(loadStaticResultsData, {
  onUpdate: true,
  onMount: true
})(StaticContent);
const StaticContentWithRedux = connect(null)(StaticContentWithDataLoaded);
const StaticContentWithCookies = withCookies(StaticContentWithRedux);

export default StaticContentWithCookies;
